import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {COLLECTION_NAMES} from '../../shared/contants/collections.const';
import {map} from 'rxjs/operators';
import {Platforms} from '@ionic/core';
import {Platform} from '@ionic/angular';
import _intersection from 'lodash/intersection';
import { UserService } from './user.service';
import { combineLatest } from 'rxjs';

export interface ITizoFeatureConfig {
    name: string;
    key: string;
    isEnabled: boolean;
    platforms: Platforms[];
    url: string;
    icon: string;
    tabName?: string;
    active?: boolean;
}

const DOCUMENT_PATH = 'features';

const PREFIX_CARD_FEATURE = 'card_';
const PREFIX_TAB_FEATURE = 'tab_';
const PREFIX_MENU_FEATURE = 'menu_';

const FEATURE_KEYS = {
    'CROSS_SELLING': 'cross_selling'
}

@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    constructor(
        private afs: AngularFirestore,
        private platform: Platform,
        private userService: UserService,
    ) {
    }

    getFeatureConfigs() {
        return this.afs
            .collection(COLLECTION_NAMES.tizoConfigs)
            .doc<{ features: ITizoFeatureConfig[] }>(DOCUMENT_PATH)
            .valueChanges()
            .pipe(
                map(res => res ? res.features : [])
            );
    }


    // Only varibale is observable start end with $,not need for function
    getVisibleFeatureConfigs() {
        return this.getFeatureConfigs().pipe(
            map(features => features.filter(feature => feature.isEnabled &&
                _intersection(this.platform.platforms(), feature.platforms || []).length > 0
            )),
        );
    }

    getVisibleCardsFeatureConfigs() {
        return this.getVisibleFeatureConfigs().pipe(
            map(features => features.filter(feature => feature.key.startsWith(PREFIX_CARD_FEATURE)))
        );
    }

    getVisibleTabsFeatureConfigs() {
        return combineLatest([
            this.userService.getVslaInfo$(),
            this.getVisibleFeatureConfigs()
        ]).pipe(
            map(([vslaInfo, features]) => {
                return features.filter(feature => {
                    return feature.key.startsWith(PREFIX_TAB_FEATURE) && (
                        !feature.key.includes('vsla') || 
                        (feature.key.includes('vsla') && vslaInfo.enableVsla)
                    );
                });
            })
        );
        // return this.getVisibleFeatureConfigs().pipe(
        //     map(features => features.filter(feature => feature.key.startsWith(PREFIX_TAB_FEATURE)))
        // );
    }

    getVisibleMenuFeatureConfigs() {
        return this.getVisibleFeatureConfigs().pipe(
            map(features => features.filter(feature => feature.key.startsWith(PREFIX_MENU_FEATURE)))
        );
    }

    isEnableFeature(featureName: string) {
        return this.getVisibleFeatureConfigs().pipe(
            map(features => features.filter(feature => feature.key.indexOf(featureName) > -1)),
            map(features=>features.length > 0 )
        )
    }
}
