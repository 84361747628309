import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'tizo-btn-primary',
  templateUrl: './btn-primary.component.html',
  styleUrls: ['./btn-primary.component.scss'],
})
export class BtnPrimaryComponent implements OnInit {
  @Input() color = 'gradient';
  @Input() disabled = false;
  @Input() fill = 'solid';
  @Input() expand = 'block';
  @Input() shape = undefined;
  @Input() size = 'normal';
  @Input() btnStyle: any;
  @Output() btnClick: EventEmitter<void> = new EventEmitter();
  constructor() { }

  ngOnInit() { }

}
