import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export interface SendOTPAPIResponse {
  otpCodeLength: number;
  otpExpiredAt: string;
  otpRemainSeconds: number;
  sentMethod: 'SMS' | 'VOICE';
}

export interface VerifyOTPAPIResponse {
  token: string,
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  constructor(
    private http: HttpClient,
  ) { }

  sendOtp(phoneNumber: string): Observable<SendOTPAPIResponse> {
    return this.http.post<SendOTPAPIResponse>(environment.functionsUrl + '/tizo/auth/send-otp', {
      phoneNumber,
    }).pipe(
      catchError(this.handleError)
    )
  }

  verifyOtp(phoneNumber: string, code: string): Observable<VerifyOTPAPIResponse> {
    return this.http.post<VerifyOTPAPIResponse>(environment.functionsUrl + '/tizo/auth/verify-otp', {
      phoneNumber,
      code,
    }).pipe(
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    const resetAtSeconds = parseInt(error.headers.get('x-ratelimit-reset'));
    const currentSeconds = (new Date).getTime() / 1000;
    let message;
    if (error.status === 429 && resetAtSeconds && resetAtSeconds >= currentSeconds) {
      const remainSeconds = Math.ceil(resetAtSeconds - currentSeconds);
      message = `Bạn đang thao tác quá nhanh. Vui lòng thử lại trong ${remainSeconds} giây nữa nhé!`
    } else {
      message = error?.error?.message || 'Có lỗi xảy ra. Vui lòng thử lại sau!'
    }
    return throwError({
      message
    });
  };
}
