import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({ name: 'cctranslate' })
export class TranslatePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {
    }

    transform(originalText: string, defaultVal?: string): string {
        return this.translateService.instant(originalText, defaultVal);
    }
}
