import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Tenant } from '@canalcircle/models';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    constructor(
        private platform: Platform,
        private afStore: AngularFirestore,
    ) {
    }

    isMobile() {
        return this.platform.is('capacitor') && (this.platform.is('ios') || this.platform.is('android'))
    }

    isIOS() {
        return this.platform.is('ios')
            && this.platform.is('capacitor');
    }

    isAndroid() {
        return this.platform.is('android')
            && this.platform.is('capacitor');
    }

    convertPhoneToE164Format(phoneNumber: string) {
        return '+84' + phoneNumber.substring(1, 10);
    }

    revertPhoneFromE164Format(phoneNumber: string) {
        return phoneNumber.replace('+84', '0');
    }

    formatCurrencyWithUnit(num) {
        if (String(num).includes('.')) {
            return num + ' đ';
        }
        // tslint:disable-next-line:use-isnan
        if (isNaN(num) || !num) {
            return '0' + ' đ';
        }
        return Number(num)
            .toLocaleString()
            .replace(/,/g, '.') + ' đ';
    }

    formatCurrencyWithoutUnit(num) {
        if (String(num).includes('.')) {
            return num;
        }
        if (isNaN(num) || !num) {
            return 0;
        }
        return Number(num)
            .toLocaleString()
            .replace(/,/g, '.');
    }

    formatDateVN(timestamp, stringFormat?) {
        let format = 'DD/MM/YYYY';
        if (stringFormat) {
            format = stringFormat;
        }
        if (isNaN(timestamp) || !timestamp) {
            return 'Không xác định';
        }
        return moment.unix(timestamp).format(format);
    }

    getTenants$(): Observable<Tenant[]> {
        return this.afStore.collection('tenants').valueChanges() as Observable<Tenant[]>;
    }
}
