import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, ConfigGuard, VslaGuard } from '@guard/index';

const routes: Routes = [
    { path: '', redirectTo: 'main-tab', pathMatch: 'full' },
    {
        path: 'main-tab',
        loadChildren: () => import('./main-tab/main-tab.module').then(m => m.MainTabPageModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
        canActivate: [ConfigGuard],
        canActivateChild: [ConfigGuard]
    },

    // global path,not include in tabs
    {
        path: 'account-info',
        loadChildren: () => import('./account/pages/account/account.module').then(m => m.AccountPageModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'on-boarding',
        loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingPageModule),
        canActivate: [ConfigGuard],
    },
    {
        path: 'loyalty',
        loadChildren: () => import('./loyalty/loyalty.module').then(m => m.LoyaltyModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'billing',
        loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'top-up',
        loadChildren: () => import('./topup/topup.module').then(m => m.TopupModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'saving',
        loadChildren: () => import('./saving/saving.module').then(m => m.SavingModule),
        canActivateChild: [ConfigGuard]
    },
    {
        path: 'e-receipt',
        loadChildren: () => import('./e-receipt/e-receipt.module').then(m => m.EReceiptModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
        canActivateChild: [ConfigGuard, AuthenticatedGuard]
    },
    {
        path: 'account-edit',
        loadChildren: () => import('./account/pages/account-edit/account-edit.module').then(m => m.AccountEditPageModule)
      },
    {
        path: 'cross-selling',
        loadChildren: () => import('./cross-selling/cross-selling.module').then(m => m.CrossSellingModule),
        canActivate: [ConfigGuard, AuthenticatedGuard],
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
