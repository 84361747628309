import { Pipe, PipeTransform, Inject } from '@angular/core';
import { MaskPipe } from 'ngx-mask';
import { ICurrencyInput } from '../models';
import { CURRENCY_CONFIG } from '../token';
import Decimal from 'decimal.js';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private maskPipe: MaskPipe, @Inject(CURRENCY_CONFIG) private config: ICurrencyInput) {

  }

  transform(value: any, roundDown = false): string {
    if (!value) {
      return '0 ' + this.config.currencySuffix;
    }
    if (typeof value === 'string') {
      value = Number(value);
    }
    value = Number(value.toFixed(2));
    if (roundDown) {
      value = Math.floor(value);
    }
    const decimal = this.getDemicalPart(value);
    // has demical
    if (decimal > 0) {
      return this.maskPipe.transform(Math.floor(value), this.config.mask, this.config.thousandSeparator)
        + ',' + decimal.toString().split('.').pop() + ' ' + this.config.currencySuffix;
    } else {
      const val = this.maskPipe.transform(value, this.config.mask, this.config.thousandSeparator);
      return val + ' ' + this.config.currencySuffix;
    }
  }

  getDemicalPart(val): number {
    const decimal = new Decimal(val).plus(-(Math.floor(val)));
    return decimal.toNumber();
  }
}
