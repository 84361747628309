import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeCountryCode'
})
export class RemoveCountryCodePipe implements PipeTransform {

  transform(value: string): any {
    if (!value) {
      return value;
    }
    return '0' + value.slice(3);
  }
}
