<ion-header>
    <ion-toolbar mode="ios">
        <ion-title class="title">{{ page?.title }}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="dismissModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <tizo-spinner *ngIf="isLoading"></tizo-spinner>
    <div class="content" *ngIf="!isLoading && !page">Không tìm thấy trang bạn yêu cầu</div>
    <div class="content" *ngIf="!isLoading && page" [innerHTML]="page.content | safe:'html'"></div>
</ion-content>