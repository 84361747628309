import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@shared/models';
import { Observable, of, combineLatest, BehaviorSubject, from } from 'rxjs';
import { Client, VSLAGroup } from '@canalcircle/models';
import { VSLA_ROLES } from '@shared/contants';
import { COLLECTION_NAMES } from '@shared/contants';
import { VSLAGroupService } from '@canalcircle/vsla-core-angular';
import firebase from 'firebase';

const USERS_COLLECTIONS = 'users';

export interface VSLAInfo {
    enableVsla: boolean
    latestGroupTerm: VSLAGroup
}


@Injectable({
    providedIn: 'root'
})
export class UserService {
    currentUser: User;
    done$ = new BehaviorSubject(false);
    constructor(
        private afAuth: AngularFireAuth,
        private afStore: AngularFirestore,
        private vslaGroupService: VSLAGroupService,
    ) {

    }


    getCurrentUserId() {
        return this.afAuth.user.pipe(map(user => user.uid), take(1));
    }

    loadCurrentUser() {
        this.afAuth.user.pipe(
            switchMap(user => user ?
                this.afStore.collection('users').doc<User>(user.uid).valueChanges() : of(null)
            )
        ).subscribe(user => {
            this.done$.next(true);
            this.currentUser = user;
        });
    }

    getCachedCurrentUser(): User {
        return this.currentUser;
    }

    getCurrentUser(): Observable<User> {
        return this.afAuth.user.pipe(
            switchMap(user => user ?
                this.afStore.collection('users').doc<User>(user.uid).valueChanges() : of(null)
            )
        );
    }

    getVslaInfo$(): Observable<VSLAInfo> {
        return this.getCurrentUser().pipe(
            switchMap(user => {
                if (!user) return combineLatest([
                    of([]),
                    of(false),
                ]);
                return combineLatest([
                    this.vslaGroupService.getGroupsForUser$(user.id),
                    of((user.roles || []).some(role => {
                        return VSLA_ROLES[role];
                    }))
                ])
            }),
            map(([groupsWithTerms, check2]) => ({
                enableVsla: groupsWithTerms.length > 0 || check2,
                latestGroupTerm: groupsWithTerms[0],
            }))
        );
    }

    getCurrentAuthUser() {
        return this.afAuth.user;
    }

    getClientByCurrentUser(): Observable<Client | null> {
        return this.afAuth.user.pipe(
            switchMap(user => !user ?
                null :
                this.getClientByUserId(user.uid)
            ),
            take(1)
        );
    }

    getCurrentUserWithClients$(): Observable<{ user: User, clients: Client[] }> {
        return this.afAuth.user.pipe(
            switchMap(user => !user ?
                of({ user: null, clients: [] }) :
                combineLatest([
                    this.afStore.collection('users').doc<User>(user.uid).valueChanges(),
                    this.getClientsByUserId$(user.uid)
                ]).pipe(
                    map(([user, clients]) => ({ user, clients }))
                )
            )
        );
    }

    getClientByUserId(userId: string): Observable<Client | null> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('uid', '==', userId)
        )
            .valueChanges()
            .pipe(map(sn => sn.length ? sn[0] : null));
    }

    getClientsByUserId$(userId: string): Observable<Client[]> {
        return this.afStore.collection<Client>(
            'clients',
            ref => ref.where('uid', '==', userId)
        ).valueChanges();
    }

    getUserById(uid: string) {
        return this.afStore.collection('users').doc<User>(uid).valueChanges();
    }

    getUserByPhoneNumber(phoneNumber: string) {
        return this.afStore.collection('users', ref => ref.where('authInfo.phoneNumber', '==', phoneNumber)).get().pipe(
            map(result => result.docs.map(d => d.data() as User)),
            map(result => result[0]),
        );
    }

    trackLastLogin(uid: string) {
        return this.upDateUserById(uid, { lastLoggedInAt: { tizo: new Date() } });
    }

    upDateUserById(uid: string, update) {
        return this.afStore.collection(USERS_COLLECTIONS).doc<User>(uid).set(update, { merge: true });
    }

    addNewTenantToCurrentUser(tenantId: string) {
        return this.afStore.collection(USERS_COLLECTIONS).doc(this.currentUser.id).update({
            tenantIds: firebase.firestore.FieldValue.arrayUnion(tenantId)
        })
    }

    async createNewAnonymousUser(params: CreateNewUserParams): Promise<User> {
        const randId = this.afStore.createId();
        const user: User = {
            id: randId,
            authInfo: {
                displayName: params.name,
                uid: '',
                email: '',
                phoneNumber: '',
                photoURL: ''
            },
            roles: [],
            tenantIds: params.tenantIds || [],
            order: 1,
            createdAt: firebase.firestore.Timestamp.now(),
            updatedAt: firebase.firestore.Timestamp.now(),
        };

        await this.afStore.collection(USERS_COLLECTIONS).doc(randId).set(user, { merge: true });

        return user;
    }
}


export interface CreateNewUserParams {
    name: string;
    tenantIds: string[];
}