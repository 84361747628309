import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputDirective } from './directives/input.directive';
import { FormFieldComponent } from './components/form-field/form-field.component';
import { FormErrorComponent } from './components/form-error/form-error.component';



@NgModule({
  declarations: [InputDirective, FormErrorComponent, FormFieldComponent],
  imports: [
    CommonModule
  ],
  exports: [
    InputDirective, FormErrorComponent, FormFieldComponent
  ]
})
export class FormFieldsModule { }
