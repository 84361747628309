export const GENDER_SELECT_LIST = [
    { name: 'Nam', id: 'female' },
    { name: 'Nữ', id: 'male' }
];
export const YES_NO_SELECT_LIST = [
    { name: 'Có', id: '1' },
    { name: 'Không', id: '0' }
];

export const PARTNERS_STATUS = [
    { name: 'Đã kết hôn', id: 'married' },
    { name: 'Chưa kết hôn', id: 'unMarried' }
];

export const LANGUAGE_USAGES = [
    { name: 'Nghe', id: 'listening' },
    { name: 'Nói', id: 'speaking' },
    { name: 'Đọc', id: 'reading' },
    { name: 'Viết', id: 'writing' }
];

export const PHONE_INTERNET_STATUSES = [
    { name: 'Có điện thoại, internet', id: 'havePhoneAndInternet' },
    { name: 'Có điện thoại, không có internet', id: 'havePhoneAndNoInternet' },
    { name: 'Không có điện thoại', id: 'noPhone' }
];

