import { Pipe, PipeTransform } from '@angular/core';

// @@Todo
// Need load from config,temporary store it in local;
const POINT_SUFFIX = 'thóc';

@Pipe({
  name: 'points'
})
export class PointsPipe implements PipeTransform {

  transform(value: string | number): string {
    if (value === null || value === undefined) {
      return `0 ${POINT_SUFFIX}`;
    }
    return `${value} ${POINT_SUFFIX}`;
  }
}

