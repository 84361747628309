import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';
import { TranslateService } from './translate.service';



@NgModule({
  declarations: [TranslatePipe],
  imports: [
    CommonModule
  ],
  exports: [
    TranslatePipe
  ],
  providers: [
    TranslatePipe
  ]
})
export class TranslateModule { }
