import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Tenant } from '@canalcircle/models';
import { catchError, map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(
    private http: HttpClient,
    private afStore: AngularFirestore,
  ) { }

  findTenantsByLocation(params: { provinceCode: string, districtCode: string, wardCode: string }): Observable<Array<Tenant>> {
    const url = environment.functionsUrl + '/tizo_v2/tenant/by-province';
    return this.http.get<{ data: Array<Tenant> }>(url, {
      params
    }).pipe(
      map(res => res.data),
      // Because dump of api =))
      catchError(e=>[])
    );
  }

  getTenantsByIds$(tenantIds: string[]) : Observable<Tenant[]> {
    return this
      .afStore
      .collection<Tenant>('tenants', ref => ref.where('id', 'in', tenantIds))
      .valueChanges();
  }

  getEnabledSavingTenantIds$() : Observable<string[]> {
    return this.afStore
      .collection<Tenant>('tenants', ref => ref.where('isEnabledSavingTizo', '==', true))
      .valueChanges()
      .pipe(
        map(tenants => tenants.map(t => t.id))
      )
  }
}
