<tizo-dialog-primary [title]="'Thông báo cập nhật'" [showCloseButton]="false">
  <div class="dialog-body">
    <p>Phiên bản mới: <b>{{ platformVersion.latestVersion }}</b></p>
    <p *ngIf="platformVersion.changeLog" [innerHTML]="platformVersion.changeLog | safe"> </p>
  </div>
  <div class="dialog-footer">
    <tizo-btn-primary *ngIf="!mandatory" class="mr-5" [color]="'red'" fill="clear"
      (btnClick)="onCancel()">
      Để sau
    </tizo-btn-primary>
    <tizo-btn-primary (btnClick)="onOk()" [color]="'primary'" shape="round">
      Cập nhật
    </tizo-btn-primary>
  </div>
</tizo-dialog-primary>