import { Component, Input, OnInit } from '@angular/core';
import { User } from '@shared/models';
import { UploadService } from '@services/upload.service';
import { UserService } from '@services/user.service';
import { IonicCoreService } from '@services/ionic-core.service';

@Component({
    selector: 'tizo-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
    @Input() user: User;
    @Input() enableUpload: boolean;
    loading = false;

    constructor(
        private uploadService: UploadService,
        private userService: UserService,
        private ionicCoreService: IonicCoreService,
    ) {
    }

    ngOnInit() {
    }

    async takePhoto() {
        if (this.loading || !this.enableUpload) {
            return;
        }
        this.loading = true;
        this.uploadService.pickImage('tizo-avatars').then(async (avatarUrl) => {
            console.log({avatarUrl});
            // in case dismiss without upload
            if (!avatarUrl) {
                return;
            }

            await this.userService.upDateUserById(this.user.id, {
                meta: {avatar: avatarUrl}
            });

            this.ionicCoreService.showToastSuccess({
                message: 'Đổi ảnh đại diện thành công'
            });
        }).catch((e) => {
            console.error(e);
            this.ionicCoreService.showToastError({
                message: 'Có lỗi khi đổi ảnh đại diện. Vui lòng thử lại sau'
            });
        }).finally(() => {
            this.loading = false;
        });
    }
}
