import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IPlatformVersionInfo, IVersionInfo, Tenant } from '@canalcircle/models';
import { COLLECTION_NAMES } from '@shared/contants';
import { UtilsService } from './utils.service';
import { default as currentApp } from '../../../../package.json';
import { ModalController } from '@ionic/angular';
import { UpdatePopupModalComponent } from '@shared/components/update-popup-modal/update-popup-modal.component';
import * as semver from 'semver';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface CommonLocation {
  id: string;
  name: string;
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  currentDialog?: HTMLIonModalElement;
  constructor(
    private afStore: AngularFirestore,
    private utilsService: UtilsService,
    private modalCtl: ModalController,
  ) {
  }

  async handleUpdate() {
    const $ = this.afStore.collection(COLLECTION_NAMES.tizoConfigs).doc('versionInfo').valueChanges();

    $.pipe(
      untilDestroyed(this),
    ).subscribe((versionInfo: IVersionInfo) => {
      console.log({ versionInfo })
      if (!versionInfo) {
        this.hidePreviousUpdatePopup();
        return;
      }

      let platformVersionInfo: IPlatformVersionInfo;
      if (this.utilsService.isIOS()) {
        platformVersionInfo = versionInfo.ios;
      } else if (this.utilsService.isAndroid()) {
        platformVersionInfo = versionInfo.android;
      }

      // platformVersionInfo = versionInfo.android;

      if (!platformVersionInfo) {
        this.hidePreviousUpdatePopup();
        return;
      }

      const currentVersion = currentApp.version;

      if (
        !semver.valid(currentVersion) ||
        !semver.valid(platformVersionInfo.latestVersion) ||
        !semver.valid(platformVersionInfo.minStableVersion)
      ) {
        console.warn('Invalid version info configuration', currentVersion, platformVersionInfo.latestVersion, platformVersionInfo.minStableVersion);
        this.hidePreviousUpdatePopup();
        return;
      }

      // >= latestVersion
      if (semver.gte(currentVersion, platformVersionInfo.latestVersion)) {
        this.hidePreviousUpdatePopup();
        return;
      } else if (
        // < minStableVersion
        semver.lt(currentVersion, platformVersionInfo.minStableVersion)
      ) {
        this.showUpdatePopUp(platformVersionInfo, true);
      } else {
        // >= minStableVersion; < latestVersion
        this.showUpdatePopUp(platformVersionInfo, false);
      }
    })
  }

  async showUpdatePopUp(latestPlatformVersion: IPlatformVersionInfo, mandatory: boolean) {
    await this.hidePreviousUpdatePopup();

    this.currentDialog = await this.modalCtl.create({
      component: UpdatePopupModalComponent,
      componentProps: {
        platformVersion: latestPlatformVersion,
        mandatory,
      },
      backdropDismiss: !mandatory,
      cssClass: 'modal-transparent',
    });

    this.currentDialog.present();
  }

  async hidePreviousUpdatePopup() {
    if (this.currentDialog) {
      await this.currentDialog.dismiss();
      this.currentDialog = null;
    }
  }
}
