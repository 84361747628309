import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export const removeFirestoreListening$ = new Subject<boolean>();

AngularFirestoreDocument.prototype.valueChanges = function () {
    return this.snapshotChanges().pipe(
        map(function (action: any) {
            return action.payload.data();
        }),
        takeUntil(removeFirestoreListening$)
    );
};
