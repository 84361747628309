import { env } from './.env';
export const environment = {
  name: 'production',
  version: env.npm_package_version + '#' + env.release.slice(0, 5),
  release: env.release,
  production: true,
  firebase: {
    apiKey: 'AIzaSyBhUl4mDpauWTIvjf8eJYnujmZJQgSYpXQ',
    authDomain: 'canalcircle-vietnam.firebaseapp.com',
    databaseURL: 'https://canalcircle-vietnam.firebaseio.com',
    projectId: 'canalcircle-vietnam',
    storageBucket: 'canalcircle-vietnam.appspot.com',
    messagingSenderId: '221744919194',
    appId: '1:221744919194:web:f3f355212263eef5',
    measurementId: "G-YJL04J1NT7",
    region: 'asia-northeast1',
  },
  functionsUrl:
    'https://asia-northeast1-canalcircle-vietnam.cloudfunctions.net',
  ALGOLIA_APP_ID: 'QGS14MHBEL',
  mixpanel: {
    token: 'b3a5099ab70c45013ad044b1598e9096',
  },
  sentryDSN: 'https://a581dcf3049d4a3d9fc436ed6967d8dd@o323676.ingest.sentry.io/5530146',
  hubspotLiveChat: '//js.hs-scripts.com/7495119.js',
  googleMapApiKey: 'AIzaSyCiazsi3H23KFG2NWitP-dWnIvsb7CJhVk',
  shopify: {
    url: 'https://canalcircle-com.myshopify.com/api/2020-07/graphql.json',
    accesstoken: '78388954c1e2eebe3387b9ce72962435',
    adminUrl: 'https://asia-northeast1-canalcircle-vietnam.cloudfunctions.net/shopify-v1'
  },
  fineractUrl: 'https://api.canalcircle.com/fineract-provider/api/v1',
};
