

export const PROVIDERS = {
    VIETTEL: 'Viettel',
    VINAPHONE: 'Vinaphone',
    MOBIPHONE: 'Mobifone',
    VNMOBILE:'VNmobile'
}; 
// Reference: https://vinasupport.com/dau-so-di-dong-cac-nha-mang-vien-thong-viet-nam/
export const PROVIDER_PHONE_NUMBER_PREFIXES = {
    [PROVIDERS.VIETTEL]: ['086', '096', '097', '098', '032', '033', '034', '035', '036', '037', '038', '039'],
    [PROVIDERS.VINAPHONE]: ['088', '091', '094', '083', '084', '085', '081', '082'],
    [PROVIDERS.MOBIPHONE]: ['089', '090', '093', '070', '079', '077', '076', '078'],
    [PROVIDERS.VNMOBILE]:['092','056','058'],
}

export function detectProvider(phoneNumber: string) : string | undefined {
    for (const [provider, prefixes] of Object.entries(PROVIDER_PHONE_NUMBER_PREFIXES)) {
        for (const prefix of prefixes) {
            if (phoneNumber.startsWith(prefix)) {
                return provider;
            }
        }
    }
    return undefined;
}
