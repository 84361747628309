import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { filter, first, map, take } from 'rxjs/operators';
import { firestore, User } from 'firebase';
import NotificationModel from '../models/Notification.model';
import { C_NOTIFICATION, ROUTES } from '@shared/contants';
import { Capacitor, Plugins, PushNotification, PushNotificationActionPerformed, PushNotificationToken } from '@capacitor/core';
import { IonicCoreService } from '@services/ionic-core.service';
import { NavController, ModalController } from '@ionic/angular';
import { Notification, UserNotificationToken, NotificationType } from '@canalcircle/models';
import { AngularFireAuth } from '@angular/fire/auth';
import { Ipaginator, IpaginatorResult } from '@shared/models';
import { Observable, Subject, combineLatest } from 'rxjs';
import { GeneralNotificationTypes, VSLANotificationTypes } from '../constants';

const { PushNotifications } = Plugins;
import { FCM } from 'capacitor-fcm';
import { UserService } from '@services/user.service';
import { PointChangeModalComponent } from '@core/modals/point-change/point-change.modal.component';

const fcm = new FCM();

const DEFAULT_PAGINATOR: Ipaginator = {
  startAfter: null,
  limit: 10
};

@Injectable({ providedIn: 'root' })
export class NotificationService {
  lastDoc: firestore.DocumentSnapshot;
  tokenRegisterSubject = new Subject<string>();
  notificationReceived$ = new Subject<string>();
  platform: string;

  constructor(
    private db: AngularFirestore,
    private ionicCoreService: IonicCoreService,
    private navController: NavController,
    private afAuth: AngularFireAuth,
    private userService: UserService,
    private modalCtrl: ModalController,
  ) {
    this.platform = Capacitor.getPlatform();
  }

  async init() {
    if (!['ios', 'android'].includes(this.platform)) {
      return;
    }

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then(result => {
      // @ts-ignored
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => this.addRegisterJob(token)
    );

    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error('Error when register push notificatin: ', JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      notification => this.handleForegroundNotification(notification)
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      notification => this.handleBackgroundNotification(notification)
    );

    combineLatest([
      this.tokenRegisterSubject.asObservable(),
      this.afAuth.user.pipe(filter(user => user !== null), map((user) => user.uid)),
    ]).subscribe(([token, userId]) => {
      this.handleRegisterToken(token, userId);
    });
  }

  public getById(id: string) {
    return this.db
      .collection(C_NOTIFICATION)
      .doc<Notification>(id)
      .get()
      .pipe(
        map(entity => new NotificationModel(entity.data() as Notification))
      );
  }

  public getById$(id: string) {
    return this.db
      .collection(C_NOTIFICATION)
      .doc<Notification>(id)
      .valueChanges()
      .pipe(
        map(entity => new NotificationModel(entity))
      );
  }

  public markAsRead(id: string) {
    return this.db
      .collection(C_NOTIFICATION)
      .doc<Notification>(id)
      .update({
        isRead: true
      });
  }

  getNewNotifications(userId: string, type: 'VSLA' | 'GENERAL', now: Date) {
    return this.db.collection<Notification>(C_NOTIFICATION, ref => {
      let query = ref.where('uid', '==', userId);
      if (type === 'VSLA') {
        query = query.where('type', 'in', VSLANotificationTypes);
      } else {
        query = query.where('type', 'in', GeneralNotificationTypes);
      }
      query = query.where('createdAt', '>', now);
      return query;
    })
      .valueChanges()
      .pipe(
        map(notifications => {
          return notifications.map(doc => new NotificationModel(doc as Notification));
        })
      );
  }

  public getByUserId(userId: string, type: 'VSLA' | 'GENERAL', paginator?: Partial<Ipaginator>)
    : Observable<IpaginatorResult<NotificationModel>> {
    const paginatorParams: Ipaginator = paginator ? { ...DEFAULT_PAGINATOR, ...paginator } : DEFAULT_PAGINATOR;
    return this.db
      .collection<Notification>(
        C_NOTIFICATION,
        ref => {
          let query = ref.where('uid', '==', userId);
          if (type === 'VSLA') {
            query = query.where('type', 'in', VSLANotificationTypes);
          } else {
            query = query.where('type', 'in', ['GENERAL']);
          }
          query = query.orderBy('createdAt', 'desc');
          if (paginator.startAfter) {
            query = query.startAfter(paginatorParams.startAfter);
          }
          query = query.limit(paginatorParams.limit);
          return query;
        }
      )
      .snapshotChanges()
      .pipe(
        map(notifications => {
          const docs = notifications.map(noti => new NotificationModel(noti.payload.doc.data()));
          const lastDoc = notifications.length > 0 ? notifications[notifications.length - 1].payload.doc : null;
          return {
            docs,
            lastDoc
          };
        })
      );
  }

  // get notification without cache
  public getByUserIdFromServer(userId: string, paginator?: Partial<Ipaginator>)
    : Observable<IpaginatorResult<NotificationModel>> {
    const paginatorParams: Ipaginator = paginator ? { ...DEFAULT_PAGINATOR, ...paginator } : DEFAULT_PAGINATOR;
    return this.db
      .collection<Notification>(
        C_NOTIFICATION,
        ref => {
          let query = ref.where('uid', '==', userId);
          query = query.orderBy('createdAt', 'desc');
          if (paginator.startAfter) {
            query = query.startAfter(paginatorParams.startAfter);
          }
          query = query.limit(paginatorParams.limit);
          return query;
        }
      )
      .get({ source: 'server' })
      .pipe(
        map(notifications => {
          const docs = notifications.docs.map(noti => new NotificationModel(noti.data() as Notification));
          const lastDoc = !notifications.empty ? notifications.docs[notifications.docs.length - 1] : null;
          return {
            docs,
            lastDoc
          };
        })
      );
  }

  private handleBackgroundNotification(notification: PushNotificationActionPerformed) {
    console.log('handleBackgroundNotification for: ');
    console.log(JSON.stringify(notification));
    try {
      this.navController.navigateForward([ROUTES.NOTIFICATION_DETAIL(notification.notification.data.id)]);
    } catch (e) {
      console.error(e);
    }
  }

  public async unRegisterToken() {
    const device = await Plugins.Device.getInfo();
    if (this.platform === 'android' || this.platform === 'ios') {
      const userId = this.afAuth.user.pipe(filter(user => user !== null), first(), map((user) => user.uid)).toPromise();
      const deviceId = device.uuid;
      if (deviceId) {
        const key = `${deviceId}_${userId}`;
        await this.db
          .collection(`userNotificationTokens`)
          .doc(`${key}`)
          .delete();
      }
      // clear old tokens for this users on this device
      this.clearTokens(deviceId, userId);
    }
  }

  private async clearTokens(deviceId, userId) {
    const tokens = await this.db
      .collection(
        'userNotificationTokens',
        ref => ref.where('uid', '==', userId)
          .where('deviceId', '==', deviceId)
      ).get().toPromise();
    if (!tokens.empty) {
      for (const doc of tokens.docs) {
        await doc.ref.delete();
      }
    }
  }

  private async handleRegisterToken(token: string, userId: string) {
    const device = await Plugins.Device.getInfo();
    const deviceId = device.uuid;

    // clear old tokens for this users this device
    await this.clearTokens(deviceId, userId);

    const data: Partial<UserNotificationToken> = {
      uid: userId,
      token,
      deviceId,
      app: 'tizo',
    };
    const key = `${deviceId}_${userId}`;
    await this.db
      .collection(`userNotificationTokens`)
      .doc(`${key}`)
      .set(data);
  }

  // Because registration event fire twice. So just emit first value end finish it
  private async addRegisterJob(token: PushNotificationToken) {
    // Get FCM token from APNS token
    if (this.platform === 'ios') {
      fcm
        .getToken()
        .then(r => {
          this.tokenRegisterSubject.next(r.token);
          // this.tokenRegisterSubject.complete();
        })
        .catch(err => this.tokenRegisterSubject.error(err));
    } else {
      // On android. Use token directly
      this.tokenRegisterSubject.next(token.value);
      // this.tokenRegisterSubject.complete();
    }
  }

  private async handleForegroundNotification(notification: PushNotification) {
    console.log('handleForegroundNotification for: ');
    console.log(JSON.stringify(notification));
    try {
      this.notificationReceived$.next(notification.data.id);
      const dbNotification = await this.db.collection('notifications').doc(notification.data.id).get().toPromise();
      const dbNotificationData = dbNotification.data() as Notification;
      // temporary ignore in case create order success
      if (dbNotificationData.type === NotificationType.POINT_PLUS) {
        this.handlePointChangeForegroundNotification(dbNotificationData);
      } else if (dbNotificationData.type === NotificationType.BUY_CODE_CARD) {
        // skip this type of notification

      } else {
        this.handleNormalForegroundNotification(dbNotificationData);
      }
    } catch (e) {
      console.error(e);
    }
  }

  private handleNormalForegroundNotification(notification: Notification) {
    this.ionicCoreService.showToastSuccess({
      header: notification.name,
      message: notification.shortContent,
      buttons: [
        {
          text: 'Xem',
          handler: () => {
            this.navController.navigateForward([ROUTES.NOTIFICATION_DETAIL(notification.id)]);
          }
        }
      ]
    });
  }

  private async handlePointChangeForegroundNotification(notification: Notification) {
    const modal = await this.modalCtrl.create({
      component: PointChangeModalComponent,
      mode: 'ios',
      cssClass: ['modal-transparent', 'modal-fullscreen'],
      showBackdrop: true,
      componentProps: {
        message: notification.shortContent,
        image: '/assets/icon/collect-point/collect-point-success.svg',
        title: notification.name,
        type: notification.type
      }
    });
    modal.present();
    modal.onDidDismiss().then(data => {

    });
  }
}
