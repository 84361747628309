import { NgModule }                   from '@angular/core';
import { CommonModule }               from '@angular/common';
import { IonicModule }                from '@ionic/angular';
import { NewFeedComponent }           from './new-feed/new-feed.component';
import { NewFeedLoadingComponent }    from './new-feed-loading/new-feed-loading.component';
import { RouterModule }               from '@angular/router';
import { VslaCardComponent }          from './vsla-card/vsla-card.component';
import { FeatureCardComponent }       from './feature-card/feature-card.component';
import { MfiPromotionCardComponent }  from './mfi-promotion-card/mfi-promotion-card.component';
import { ApplicationCardComponent }   from './application-card/application-card.component';
import { LocationAlertCardComponent } from './location-alert-card/location-alert-card.component';
import { NewsCategoryComponent }      from './news-category/news-category.component';
import { InputCurrencyModule }        from '@shared/input-currency/input-currency.module';
import { CtaCardComponent }           from './cta-card/cta-card.component';
import { SharedModule }               from '@shared/shared.module';
import { ProductCollectionComponent } from './product-collection/product-collection.component';
import { CrossSellingCardComponent }  from './cross-selling-card/cross-selling-card.component';
import { HomeSlideComponent } from './home-slide/home-slide.component';
import { RefererCardComponent } from './referer-card/referer-card.component';
import { CcFormV2Module } from '@shared/cc-form-v2/cc-form-v2.module';
import { RefererCodeComponent } from './referer-code/referer-code.component';
import { InputPhoneModule } from '@shared/input-phone/input-phone.module';
@NgModule({
    declarations: [
        NewFeedComponent,
        NewFeedLoadingComponent,
        VslaCardComponent,
        FeatureCardComponent,
        MfiPromotionCardComponent,
        ApplicationCardComponent,
        LocationAlertCardComponent,
        VslaCardComponent,
        NewsCategoryComponent,
        NewFeedComponent,
        CtaCardComponent,
        ProductCollectionComponent,
        CrossSellingCardComponent,
        HomeSlideComponent,
        RefererCardComponent,
        RefererCodeComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        InputCurrencyModule,
        SharedModule,
        CcFormV2Module,
        InputPhoneModule,
    ],
    exports: [
        NewFeedComponent,
        NewFeedLoadingComponent,
        VslaCardComponent,
        FeatureCardComponent,
        MfiPromotionCardComponent,
        ApplicationCardComponent,
        LocationAlertCardComponent,
        VslaCardComponent,
        NewsCategoryComponent,
        NewFeedComponent,
        CtaCardComponent,
        ProductCollectionComponent,
        CrossSellingCardComponent,
        HomeSlideComponent,
        RefererCardComponent,
        RefererCodeComponent,
    ]
})
export class ComponentsModule {
}
