import {CurrencyFieldComponent} from './currency-field/currency-field.component';
import {SelectFieldComponent} from './select-field/select-field.component';
import {ImageUploadFieldComponent} from './image-upload-field/image-upload-field.component';
import {PhoneFieldComponent} from './phone-field/phone-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StringFieldComponent} from './string-field/string-field.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RadioFieldComponent} from './radio-field/radio-field.component';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {PipesModule} from '@shared/pipes/pipes.module';
import {SharedModule} from '@shared/shared.module';
import {DateTimeComponent} from './date-time/date-time.component';
import { TextareaFieldComponent } from './text-area-field/text-area-field.component';
import { MultipleSelectFieldComponent } from './multiple-select-field/multiple-select-field.component';
import { MultiSelectPopup } from './multiple-select-field/multi-select-popup/multi-select-popup.component';


@NgModule({
    declarations: [
        StringFieldComponent,
        TextareaFieldComponent,
        RadioFieldComponent,
        PhoneFieldComponent,
        ImageUploadFieldComponent,
        SelectFieldComponent,
        CurrencyFieldComponent,
        DateTimeComponent,
        MultipleSelectFieldComponent,
        MultiSelectPopup,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        PipesModule,
    ],
    exports: [
        StringFieldComponent,
        TextareaFieldComponent,
        RadioFieldComponent,
        PhoneFieldComponent,
        ImageUploadFieldComponent,
        SelectFieldComponent,
        CurrencyFieldComponent,
        DateTimeComponent,
        MultipleSelectFieldComponent,
    ],
    providers: [
        MaskPipe
    ],
    entryComponents: [
        MultiSelectPopup,
    ]
})
export class CcFormV2Module {
}
