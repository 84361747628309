import { firestore } from 'firebase';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Notification, NotificationType } from '@canalcircle/models';
import { ROUTES } from '@shared/contants';
import { GeneralNotificationTypes } from '../constants';

export default class NotificationModel {
  private datePipe: DatePipe;
  actionText: string;
  actionLink: string;

  constructor(
    public notification: Notification,
  ) {
    // @TODO: find a way to share instance of datepipe
    this.datePipe = new DatePipe('en_US');
    this.setExtraInfo();
  }

  setExtraInfo() {
    const type = this.getType();

    switch (type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.LOAN_DISBURSEMENT:
        this.actionLink = ROUTES.APPLICATION_OVERVIEW(this.getReference());
        break;
      case NotificationType.TIZO_CTA:
      case NotificationType.MARKETING:
        this.actionLink = this.getReference();
        break;
    }

    switch (type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.APPLICATION_APPROVED:
        this.actionText = 'Xem chi tiết đơn vay';
        break;
      case NotificationType.TIZO_CTA:
        this.actionText = 'Xem ngay';
        break;
      case NotificationType.MARKETING:
        this.actionText = this.actionLink ? 'Xem ngay' : null;
        break;
      case NotificationType.BUY_CODE_CARD:
        this.actionText = 'Sao chép mã thẻ';
        break;
      default:
        this.actionText = 'Xem chi tiết'
    }
  }

  getId() {
    return this.notification.id;
  }

  getTitle() {
    return this.notification.name;
  }

  getIsRead() {
    return this.notification.isRead;
  }

  getContent() {
    return this.notification.content;
  }

  getType() {
    return this.notification.type;
  }

  getReference() {
    return this.notification.reference;
  }

  getMetaData() {
    return this.notification.metaData;
  }

  getShortContent() {
    return this.notification.shortContent;
  }

  getCreatedAtFormmated() {
    return this.datePipe.transform((this.notification.createdAt as firestore.Timestamp).toDate(), 'dd/MM/yyyy');
  }

  getCreatedAtAgo() {
    return moment((this.notification.createdAt as firestore.Timestamp).toDate()).locale('vi_VN').fromNow();
  }

  getNotificationIcon() {
    switch (this.notification.type) {
      case NotificationType.LOAN_REQUEST_REJECT:
      case NotificationType.LOAN_REQUEST_ACCEPT:
      case NotificationType.LOAN_REQUEST_SUBMITTED:
      case NotificationType.LOAN_REQUEST_CLAIMED:
      case NotificationType.APPLICATION_APPROVED:
        return 'assets/notification_icons/application.svg';
      case NotificationType.TIZO_CTA:
      case NotificationType.MARKETING:
        return 'assets/notification_icons/system.svg';
      case NotificationType.BUY_CODE_CARD:
        return 'assets/notification_icons/token.svg';
      case NotificationType.VSLA_NEW_MEETING_INVITATION:
        return 'assets/notification_icons/group.svg';
      default:
        return 'assets/notification_icons/system.svg';
    }
  }
}
