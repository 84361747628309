import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NetworkService } from '@shared/network/service/network.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'tizo-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],
})
export class NetworkComponent implements OnInit {
  isOffline:boolean;
  constructor(
    private networkService: NetworkService,
    private cdr:ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.networkService.listenStatusChange();
    this.networkService.getStatus().pipe(
      filter(status => status !== null)
    ).subscribe(status => {
      if (!status.connected) {
        this.isOffline = true;
      } else {
        this.isOffline = false;
      }
      this.cdr.detectChanges();
    })
  }
}
