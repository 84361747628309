import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@services/user.service';
import { take, tap, map } from 'rxjs/operators';
import { IonicCoreService } from '@services/ionic-core.service';
import { NavController } from '@ionic/angular';
import { ROUTES } from '@shared/contants';

@Injectable({
  providedIn: 'root'
})
export class VslaGuard implements CanActivate, CanActivateChild {
  constructor(
    private userService: UserService,
    private ionicCoreService: IonicCoreService,
    private navCtrl: NavController
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getVslaInfo$().pipe(
      tap(info => {
        if (!info.enableVsla) {
          this.navCtrl.navigateForward([ROUTES.MAIN_TAB]);
          this.ionicCoreService.showToastError({ message: 'Bạn chưa tham gia nhớm VSLA,vui lòng liên hệ trưởng nhóm để tham gia' })
        }
      }),
      take(1),
      map(info => info.enableVsla)
    );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getVslaInfo$().pipe(
      tap(info => {
        if (!info.enableVsla) {
          this.navCtrl.navigateForward([ROUTES.MAIN_TAB]);
          this.ionicCoreService.showToastError({ message: 'Bạn chưa tham gia nhớm VSLA,vui lòng liên hệ trưởng nhóm để tham gia' })
        }
      }),
      take(1),
      map(info => info.enableVsla)
    );
  }
}
