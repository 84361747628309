<div
    class="network-status"
    [ngClass]="isOffline ? 'offline-status' : 'online-status'">
    <div *ngIf="isOffline;else reConnect">
      <div>Không tìm thấy kết nối mạng</div>
      <div>Vui lòng kiểm tra kết nối wifi,3g</div>
    </div>
</div>

<ng-template #reConnect>
  <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-spinner style="width: 15px;"></ion-spinner>
    <div class="ion-text-left ion-margin-start">
      Đang kết nối lại...
    </div>
  </ion-row>
</ng-template>