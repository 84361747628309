import { NgModule } from '@angular/core';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import {InputCurrencyModule} from '@shared/input-currency/input-currency.module';
import {IonicModule} from '@ionic/angular';
import { TranslateModule } from './translate/translate.module';


@NgModule({
  declarations: [],
  imports: [
  ],
  exports: [
    ComponentsModule,
    PipesModule,
    InputCurrencyModule,
    DirectivesModule,
    ReactiveFormsModule,
    RoundProgressModule,
    IonicModule,
    TranslateModule
  ]
})
export class SharedModule { }
