import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { CommonLocation, ConfigService } from '@services/config.service';
import Fuse from 'fuse.js';
import { Loader } from '@canalcircle/google-maps';
import { environment } from '@environments/environment';
import { removeHartVn } from '@shared/utils';
import GeocoderResult = google.maps.GeocoderResult;
import Geocoder = google.maps.Geocoder;

const {Geolocation} = Plugins;

export interface CommonGeoLocation {
    long: number;
    lat: number;
    alt: number;
}

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private geoCoder: Geocoder;

    constructor(
        private configService: ConfigService
    ) {
    }

    async init() {
        const loader = new Loader(environment.googleMapApiKey, {
            language: 'vi'
        });
        const google = await loader.load();
        this.geoCoder = new google.maps.Geocoder();
    }

    async getCurrentLocation(): Promise<CommonGeoLocation> {
        const result = await Geolocation.getCurrentPosition({
            enableHighAccuracy: true
        });
        return {
            lat: result.coords.latitude,
            long: result.coords.longitude,
            alt: result.coords.altitude,
        };
    }

    async encodeLatLongToAddress(geo: CommonGeoLocation): Promise<{
        province: CommonLocation,
        district: CommonLocation,
        ward: CommonLocation,
        geoPoint: CommonGeoLocation,
    }> {
        // @todo: can improve this
        if (!this.geoCoder) {
            await this.init();
        }
        return new Promise((resolve, reject) => {
            try {
                this.geoCoder.geocode({
                        location: {
                            lat: geo.lat,
                            lng: geo.long
                        },
                    },
                    (results, status) => {
                        if (!Array.isArray(results) || results.length <= 2) {
                            console.log('geocode response: ', results, status);
                            return reject(`Can not get result from google map api`);
                        }
                        this.extractAdminstrativeNames(results).then(locations => {
                            resolve({
                                ...locations,
                                geoPoint: geo,
                            });
                        }).catch(e => {
                            reject(e);
                        });
                    }
                );
            } catch (e) {
                reject(e);
            }
        });
    }

    async decodeAddressToLatLong(
        province: CommonLocation,
        district: CommonLocation,
        ward: CommonLocation,
        customAddress: string,
    ): Promise<CommonGeoLocation> {
        console.log(`decodeAddressToLatLong: `, province, district, ward, customAddress);
        // @todo: can improve this
        if (!this.geoCoder) {
            await this.init();
        }
        const address = `${customAddress} ${ward.name} ${district.name} ${province.name}`;
        return new Promise((resolve, reject) => {
            this.geoCoder.geocode({address}, results => {
                if (results && results[0]) {
                    resolve({
                        long: results[0].geometry.location.lng(),
                        lat: results[0].geometry.location.lat(),
                        alt: null,
                    });
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    private async extractAdminstrativeNames(results: GeocoderResult[]): Promise<{
        province: CommonLocation,
        district: CommonLocation,
        ward: CommonLocation,
    }> {
        let provinceName, districtName, wardName: string;
        let province, district, ward: CommonLocation;

        for (const result of results.reverse()) {
            if (result.types.includes('administrative_area_level_1')) {
                provinceName = result.address_components.find(
                    component => component.types.includes('administrative_area_level_1')
                ).short_name;
                province = await this.convertLocationNameToInternalData('province', provinceName);
            }
            if (province && result.types.includes('administrative_area_level_2')) {
                districtName = result.address_components.find(
                    component => component.types.includes('administrative_area_level_2')
                ).short_name;
                district = await this.convertLocationNameToInternalData('district', districtName, province.id);
            }
            if (district && result.types.includes('administrative_area_level_3')) {
                wardName = result.address_components.find(
                    component => component.types.includes('administrative_area_level_3')
                ).short_name;
                ward = await this.convertLocationNameToInternalData('ward', wardName, district.id);
            }
            if (province && district && ward) {
                break;
            }
        }

        return {
            province,
            district,
            ward,
        };
    }

    private async convertLocationNameToInternalData(
        type: 'district' | 'province' | 'ward',
        name: string,
        parentId?: string
    ): Promise<CommonLocation> {
        let candidates;
        let result: CommonLocation;
        switch (type) {
            case 'province':
                candidates = await this.configService.getProvices().toPromise();
                result = this.pickCandidate(candidates, name);
                break;
            case 'district':
                candidates = await this.configService.getDistrictsByProvice(parentId).toPromise();
                result = this.pickCandidate(candidates, name);
                break;
            case 'ward':
                candidates = await this.configService.getWardByDistricts(parentId).toPromise();
                result = this.pickCandidate(candidates, name);
                break;
        }
        if (!result) {
            throw new Error(`Can not get ${type} ${name}. With parent id: ${parentId}`);
        }
        return result;
    }

    private pickCandidate(candidates: CommonLocation[], name: string) {
        // @todo: can improve by pre optimize json file
        const normalizedCandidates = candidates.map(candidate => ({
            ...candidate,
            normalizedName: removeHartVn(candidate.name),
        }));
        const fuse = new Fuse(normalizedCandidates, {
            keys: ['normalizedName'],
            shouldSort: true,
            includeScore: true,
        });
        const result = fuse.search(removeHartVn(name));
        return result[0]?.item;
    }
}
