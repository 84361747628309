import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    Clipboard,
    PhotoViewer
  ]
})
export class IonicNativeModule {
}
