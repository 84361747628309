import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Client } from '@canalcircle/models';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, of } from 'rxjs';
import { catchError, filter, switchMap, timeout } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { TenantService } from './tenant.service';
import { UserService } from './user.service';
export interface IRole {
  id: number;
  name: string;
  description: string;
  disabled: boolean;
}

export interface IAuthenticateResponse {
  username: string;
  userId: number;
  base64EncodedAuthenticationKey: string;
  authenticated: boolean;
  officeId: number;
  officeName: string;
  roles: IRole[];
  permissions: string[];
  shouldRenewPassword: boolean;
  isTwoFactorAuthenticationRequired: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class FineractService {
  readonly TIMEOUT = 5000;
  done$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authenticatedClients$ = new BehaviorSubject<Client[]>([]);
  tokensByTenant: Map<string, string> = new Map;
  baseUrl = environment.fineractUrl;

  constructor(
    private httpClient: HttpClient,
    private tenantService: TenantService,
    private authService: AuthService,
    private userService: UserService,
  ) {
  }

  init() {
    return this.authService.getCurrentToken$().pipe(
      switchMap(token => combineLatest([
        token ? this.tenantService.getEnabledSavingTenantIds$() : of([]),
        of(token),
        token ? this.userService.getCurrentUserWithClients$() : of({ user: null, clients: [] }),
      ])),
      switchMap(([enabledTenantIds, firebaseToken, { user, clients }]) => {
        const sufficentClients = clients.filter(client => client.tenantId && client.externalIdInfo?.[client.tenantId]);
        const sufficentClientIds = sufficentClients.map(c => c.tenantId);
        const targetTenantIds = _.intersection(enabledTenantIds, sufficentClientIds);
        console.log({ enabledTenantIds, targetTenantIds, firebaseToken, user, clients });

        if (targetTenantIds.length === 0 || !firebaseToken || !user) {
          return of([]);
        }

        return forkJoin(
          targetTenantIds.map(
            tenantId => this.getFineractToken(tenantId, firebaseToken, user.authInfo.phoneNumber)
              .then(token => ({
                token,
                tenantId,
                client: clients.find(c => c.tenantId === tenantId)
              }))
          )
        )
      })
    ).subscribe((pairs) => {
      const authenticatedClients = [];
      this.tokensByTenant.clear();
      pairs.forEach(({ token, tenantId, client }) => {
        if (!token) {
          this.tokensByTenant.delete(tenantId);
        } else {
          authenticatedClients.push(client);
          this.tokensByTenant.set(tenantId, token);
        }
      });
      this.done$.next(true);
      this.authenticatedClients$.next(authenticatedClients);
    }, error => {
      console.error(error);
      this.done$.next(true);
    }, () => {
      console.log('INIT COMPLETED');
    });
  }

  async getFineractToken(tenantId: string, firebaseToken: string, phoneNumber: string): Promise<string> {
    const response = await this.httpClient.post<IAuthenticateResponse>(this.baseUrl + '/authentication', {
      phone_number: phoneNumber,
      firebaseToken,
    }, {
      headers: this.prepareHeaderForTenant(tenantId),
    })
      .pipe(
        timeout(3000),
        catchError(e => {
          console.error(e);
          return of(null);
        })
      )
      .toPromise<IAuthenticateResponse>();

    return response?.base64EncodedAuthenticationKey;
  }

  prepareHeaderForTenant(tenantId: string) {
    return new HttpHeaders({
      'fineract-platform-tenantid': tenantId,
      'Accept': 'application/json'
    });
  }
}
