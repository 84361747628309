export const C_APPLICATION = 'applications';
export const C_NOTIFICATION = 'notifications';

// clone from vsla
export const COLLECTION_NAMES = {
  users: 'users',
  roles: 'roles',
  clients: 'clients',
  tizoVslaGroups: 'tizoVslaGroups',
  tizoVslaTerms: 'tizoVslaTerms',
  tizoVslaMeetings: 'tizoVslaMeetings',
  tizoVslaTermUsers: 'tizoVslaTermUsers',
  tizoVslaMeetingUsers: 'tizoVslaMeetingUsers',
  tizoVslaLoans: 'tizoVslaLoans',
  notifications: 'notifications',
  smsJobs: 'smsJobs',
  pointsTransactions: 'pointsTransactions',
  tizoConfigs: 'tizoConfigs',
  giftProviders: 'giftProviders',
  gifts: 'gifts',
  campaigns: 'campaigns',
  giftTypes: 'giftTypes'
};
