import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { tap } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { ROUTES } from '@shared/contants';
import { Plugins } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().pipe(
      tap(async (isAuthenticated) => {
        const device = await Plugins.Device.getInfo();
        if (!isAuthenticated) {
          if (device.platform === 'web') {
            this.navCtrl.navigateRoot(ROUTES.ROOT_PAGE_ON_WEB);
          } else {
            this.navCtrl.navigateRoot(ROUTES.ROOT_PAGE_ON_MOBILE);
          }
        }
      })
    )
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().pipe(
      tap(async (isAuthenticated) => {
        const device = await Plugins.Device.getInfo();
        if (!isAuthenticated) {
          if (device.platform === 'web') {
            this.navCtrl.navigateRoot(ROUTES.ROOT_PAGE_ON_WEB);
          } else {
            this.navCtrl.navigateRoot(ROUTES.ROOT_PAGE_ON_MOBILE);
          }
        }
      })
    );
  }
}
