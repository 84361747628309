<tizo-network></tizo-network>
<ion-app>
    <ion-menu
        side="start"
        type="overlay"
        contentId="main">
        <ion-header>
            <ion-toolbar mode="ios">
                <ion-menu-toggle>
                    <ion-item
                        lines="none"
                        button
                        detail="false"
                        (click)="goToProfileEditPage()">
                        <tizo-avatar
                            slot="start"
                            [user]="user$ | async"></tizo-avatar>
                        <ion-label>
                            <div class="font-weight-bold">
                                {{(user$ | async) ? (user$ | async)?.authInfo?.displayName : '' }}
                            </div>
                            <ion-text
                                class="font-mini mt-5"
                                color="primary">
                                Sửa thông tin
                            </ion-text>
                        </ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ng-container *ngIf="(isAvailaleReferer$ | async) as isAvailaleReferer">
                    <ion-menu-toggle *ngIf="isAvailaleReferer">
                        <tizo-referer-code [user]="user$ | async"></tizo-referer-code>
                    </ion-menu-toggle>
                </ng-container>
                <ion-menu-toggle *ngFor="let feature of features">
                    <ion-item
                        *ngIf="isFeatureActive(feature)"
                        button
                        [routerLink]="feature.url"
                        lines="full"
                        detail="true">
                        <ion-icon
                            [src]="feature.icon"
                            slot="start"></ion-icon>
                        <ion-label>{{feature.name}}</ion-label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle>
                    <ion-item
                        button
                        (click)="onClickLogOut()"
                        lines="full"
                        detail="true">
                        <ion-icon
                            src="/assets/icon/log-out.svg"
                            slot="start"></ion-icon>
                        <ion-label>Đăng xuất</ion-label>
                    </ion-item>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-toolbar>
                <div class="ion-text-center">
                    <small>
                        <i>Version {{ version }} - {{ build }} - {{ envName }}</i>
                    </small>
                </div>
                <div class="font-mini ion-text-center ion-padding">
                    <span
                        (click)="showTermModal()"
                        style="text-decoration: underline;color: #3d5eb0">
                        Điều khoản
                    </span>
                    và
                    <span
                        (click)="showPolicyModal()"
                        style="text-decoration: underline;color: #3d5eb0">
                        chính sách
                    </span>
                </div>
            </ion-toolbar>
        </ion-footer>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
