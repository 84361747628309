import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'tizo-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @Input() fixed:boolean;
  constructor() { }

  ngOnInit() {}

}
