import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Storage } from '@ionic/storage';
const STORAGE_LANGUAGES_KEY = 'tizo_storage_languages';
const STORAGE_LAST_UPDATE_LANGUAGES_KEY = 'tizo_storage_last_update_languages';
import * as moment from 'moment';
export interface ITranslationService {
  instant(v: string, defaultV?: string);

  setLanguage?(language: string): Promise<boolean>;

  getLanguage(lang: string): string;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService implements ITranslationService {
  language = 'vi';
  translation: any = {};
  done$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private afStore: AngularFirestore,
    private storageCtrl: Storage
  ) { }

  async loadTranslations() {
    try {
      const shouldUpdate = await this.shouldReDownloadLangs();
      if (shouldUpdate) {
        const { translations, updatedAt } = await this.downLoadTranslations();
        await this.setLangsToLocalStorage(translations);
        await this.storageCtrl.set(STORAGE_LAST_UPDATE_LANGUAGES_KEY, updatedAt);
        this.translation = translations;
      } else {
        this.translation = await this.loadLangsFromLocalStorage();
      }
      this.done$.next(true);
    } catch (error) {
      this.done$.next(true);
      console.log('load error when load translations');
    }
  }

  instant(v, defaultV?) {
    if (this.translation) {
      return this.translation[v] ? this.translation[v] : defaultV || v;
    } else {
      return v;
    }
  }
  // Currently only use Vi language
  async downLoadTranslations(): Promise<{ translations: any, updatedAt: any }> {
    let translations = {};
    const translationsVi = (await this.afStore
      .collection('translations').doc('vi').get().toPromise()).data();
    let updatedAt = new Date(translationsVi.updatedAt.seconds * 1000);
    const tv = translationsVi;
    const translationEng = (await this.afStore
      .collection('translations').doc('en').get().toPromise()).data();
    translations = tv;
    if (translationEng) {
      if (translations && tv) {
        for (const [k, v] of Object.entries(translationEng as { [key: string]: string })) {
          // console.log([v, k])
          translations[v] = tv[k];
        }
      }
    }
    return { translations, updatedAt };
  }
  // load languages from local storage
  loadLangsFromLocalStorage() {
    return this.storageCtrl.get(STORAGE_LANGUAGES_KEY).then(res => JSON.parse(res) || {});
  }
  // set languages to local storage
  setLangsToLocalStorage(langs: any) {
    return this.storageCtrl.set(STORAGE_LANGUAGES_KEY, JSON.stringify(langs));
  }
  // get last update time
  getLastUpdatedTime() {
    return this.storageCtrl.get(STORAGE_LAST_UPDATE_LANGUAGES_KEY).then(res => {
      if (res) {
        return new Date(res);
      }
    });
  }
  // Currently only use Vi language
  async shouldReDownloadLangs(lang = 'vi'): Promise<boolean> {
    const translations = await this.afStore.collection('translations').doc(lang).get().toPromise();
    const lastUpdated = await this.getLastUpdatedTime();
    const updatedAt: firebase.firestore.Timestamp = translations.data().updatedAt;
    return lastUpdated ? moment(lastUpdated).isBefore(new Date(updatedAt.seconds * 1000)) : true;
  }
  getLanguage() {
    return this.language || 'vi';
  }
}
