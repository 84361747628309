import { Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { version } from '../../../../package.json';

export const TRACKING_EVENTS = {
    OPEN_APP: 'Open application', //
    VIEW_ONBOARDING: 'View onboarding', //
    LOGIN: 'Login', //
    SIGNUP: 'Sign up', //
    LOGOUT: 'Logout', //
    UPDATE_BASIC_INFO: 'Update basic info', //
    UPDATE_ADVANCE_INFO: 'Update advance info', //
    UPDATE_VSLA_INFO: 'Update VSLA info', //
    VIEW_LOAN_APPLICATIONS: 'View loan applications list', //
    VIEW_LOAN_APPLICATION_DETAIL: 'View loan application detail', //
    CREATE_APPLICATION: 'Create application', //
    SUBMIT_APPLICATION: 'Submit application', //
    DELETE_APPLICATION: 'Delete application', //
    ENTER_VSLA_FUNCTION: 'Enter VSLA function', //
    CREATE_VSLA_GROUP: 'Create VSLA group', //
    VIEW_VSLA_BOOKLET: 'View VSLA booklet', //
    VIEW_VSLA_MEMBERS: 'View VSLA members list', //
    VIEW_VSLA_RULES: 'View VSLA rules list', //
    VIEW_VSLA_MEETINGS: 'View VSLA meetings list', //
    CREATE_VSLA_MEETING: 'Create VSLA meeting', //
    VIEW_VSLA_MEETING_DASHBOARD: 'View VSLA meeting dashboard', //
    VIEW_VSLA_ATTENDANCES: 'View VSLA attendances list', //
    VIEW_VSLA_MUTUAL_FUNDS: 'View VSLA mutual funds', //
    VIEW_VSLA_STOCKS_LIST: 'View VSLA stocks list', //
    VIEW_VSLA_REPAYMENTS: 'View VSLA repayments list', //
    VIEW_VSLA_LOANS: 'View VSLA loans list', //
    VIEW_VSLA_MEETING_SUMMARY: 'View VSLA meeting summary',
    CLOSE_MEETING: 'Close VSLA meeting', //
};

@Injectable({
    providedIn: 'root'
})
export class MixpanelService {
    constructor() {
    }

    init(): void {
        mixpanel.init(environment.mixpanel.token, {
            loaded: mix => {
            //   console.log('Distinct ID: ', mix.get_distinct_id());
            }
        });

        mixpanel.register({
            app_version: version
        });
    }

    identifyUser(id: string) {
        mixpanel.identify(id);
    }

    track(id: string, action: any = {}): void {
        // console.log('mix panel tracking: ', id);
        mixpanel.track(id, { data: action });
    }

    alias(id) {
        mixpanel.alias(id);
    }

    setPeople(data: any = {}): void {
        // console.log('set people: ', data);
        mixpanel.people.set(data);
    }

    reset() {
        mixpanel.reset();
    }
}
