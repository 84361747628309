import { SavingProductEnum } from "@canalcircle/models";

export const ROUTES = {
  ROOT_PAGE: 'on-boarding',
  ROOT_PAGE_ON_WEB: '/auth/login',
  ROOT_PAGE_ON_MOBILE: 'on-boarding',
  // Main tab
  MAIN_TAB: 'main-tab',

  // Application module
  APPLICATION_LIST: () => `/main-tab/applications`,
  APPLICATION_CREATE: () => `/main-tab/applications/create`,
  APPLICATION_DETAIL: id => `/main-tab/applications/detail/${id}`,
  APPLICATION_OVERVIEW: id => `/main-tab/applications/overview/${id}`,

  // Notification module
  NOTIFICATION_LIST: () => `/main-tab/notifications`,
  NOTIFICATION_DETAIL: id => `/main-tab/notifications/detail/${id}`,

  // Account
  ACCOUNT: () => `/main-tab/account`,
  ACCOUNT_INFO: '/account-info',
  ACCOUNT_PREVIEW: '/main-tab/account/account-preview',
  ACCOUNT_EDIT: '/account-edit',
  LOGIN: () => '/auth/login',

  // VSLA
  VSLA_GROUP_LIST: () => `main-tab/vsla/groups/`,
  VSLA_LOADER: () => `main-tab/vsla/loader/`,

  // On boarding page
  ON_BORADING: 'on-boarding',

  // Home routing
  NEWS_CATEGORY_DETAIL: (id: string) => `/main-tab/home/news-category-detail/${id}`,
  NEW_FEED_DETAIL: `/main-tab/home/new-feed-detail`,

  // loyalty module
  LOYALTY_LIST: '/loyalty',
  EXCHANGE_POINT: '/loyalty/exchange-point',
  COLLECT_POINT: '/loyalty/collect-point',
  CAMPAIGN_DETAIL: `/loyalty/campaign-detail`,
  // billing
  BILLING_TYPE_LIST: () => `/billing/billing-types`,
  BILLING_PROVIDER_LIST: (id: string) => `/billing/billing-providers/${id}`,
  BILLING_PROVIDER_DETAIL: (id: string) => `/billing/billing-provider-details/${id}`,
  BILLING_DETAIL: (pId: string, dId) => `/billing/billing-provider-details/${pId}/detail/${dId}`,

  // Top up
  TOP_UP: '/top-up',
  SAVING: 'saving',
  SCAN_QR: 'scan-qr',

  // Cross selling
  CROSS_SELLING: '/cross-selling',
  CROSS_SELLING_CART: '/cross-selling/cart',
  CROSS_SELLING_COLLECTIONS: '/cross-selling/collections',
  CROSS_SELLING_SEARCH: '/cross-selling/search',
  CROSS_SELLING_CHECKOUT: '/cross-selling/checkout',
  CROSS_SELLING_CHECKOUT_DONE: '/cross-selling/checkout-done',
  CROSS_SELLING_ORDERS: '/cross-selling/orders',
  CROSS_SELLING_ORDER_DETAIL: (id: string) => `/cross-selling/order-detail/${id}`,
  CROSS_SELLING_COLLECTION: (id: string) => `/cross-selling/collection-detail/${id}`,
  CROSS_SELLING_PRODUCT_DETAIL: (id: string) => `/cross-selling/product-detail/${id}`,

  // My savings
  SAVING_HOME: '/saving',
  SAVING_CREATE_1: '/saving/create/step-1',
  SAVING_CREATE_2: '/saving/create/step-2',
  SAVINGS_LIST: '/saving/list',
  CREATE_SAVING_REQUESTS_LIST: '/saving/requests',
  SAVING_DETAIL: (savingType: SavingProductEnum, tenantId: string, savingId: string) => `/saving/detail/${savingType}/${tenantId}/${savingId}`,
  SAVING_DEPOSIT_WITHDRAWAL: (savingType: SavingProductEnum, tenantId: string, savingId: string) => `/saving/deposit/${savingType}/${tenantId}/${savingId}`,
};


