import { Injectable, OnDestroy } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase';
import {VN_PHONE_NUMER_PREFIX} from '@shared/contants';
import { first, map, timeout, takeUntil } from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {from, Observable, Subject} from 'rxjs';
import {environment} from '@environments/environment';
import {HttpClient} from '@angular/common/http';
import {Plugins} from '@capacitor/core';
import {MixpanelService, TRACKING_EVENTS} from './mixpanel.service';
import {Platform} from '@ionic/angular';
import {cfaSignIn, cfaSignInPhoneOnCodeReceived, cfaSignInPhoneOnCodeSent, cfaSignOut} from '@canalcircle/capacitor-firebase-auth';
import {UtilsService} from './utils.service';
import { NotificationService } from '../../notification/services/notification.service';
import { AuthApiService, SendOTPAPIResponse } from '@api/auth-api.service';
import { removeFirestoreListening$ } from '@patches/fix-sign-out-subscription';
import * as Sentry from '@sentry/angular';

@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    recaptchaVerifier: firebase.auth.RecaptchaVerifier;
    autoRetrieveCodeSubject$ = new Subject<{ verificationId: string, verificationCode: string }>();
    autoLoginSubject$ = new Subject<firebase.User>();
    
    constructor(
        private notificationService: NotificationService,
        private afAuth: AngularFireAuth,
        private fs: AngularFirestore,
        private http: HttpClient,
        private authApiService: AuthApiService,
        private mixpanelService: MixpanelService,
        private platform: Platform,
        private utilsService: UtilsService,
    ) {
        if (this.utilsService.isMobile()) {
            cfaSignInPhoneOnCodeReceived().subscribe(event => {
                console.log('cfaSignInPhoneOnCodeReceived: ', event);
                this.autoRetrieveCodeSubject$.next(event);
            });
        }
        this.afAuth.user.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user)=>{
            Sentry.configureScope((scope) => {
                const tags: {email?, phoneNumber?} = {};
                if(user?.email){
                    tags.email = user.email;
                }
                if(user?.phoneNumber){
                    tags.phoneNumber = user.phoneNumber;
                }
                scope.setTags(tags);
                scope.setUser({
                    id: user ? user.uid : '', //
                    displayName: user ? user.displayName : '',
                    phoneNumber: user ? user.phoneNumber : '',
                    email: user ? user.email : '',
                    version: environment.version,
                    app: 'tizo',
                });
            });
        })
    }

    ngUnsubscribe = new Subject();
    ngOnDestroy(): void {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    sendVerificationCode(phoneNumber: string): Observable<SendOTPAPIResponse> {
        return this.authApiService.sendOtp(phoneNumber);
    }

    // sendVerificationCode(phoneNumber: string): Observable<string> {
    //     if (this.utilsService.isMobile()) {
    //         return this.handleLoginNative(phoneNumber);
    //     }
    //     return this.handleLoginWeb(phoneNumber);
    // }

    handleLoginWeb(phoneNumber: string) {
        if (this.recaptchaVerifier) {
            this.recaptchaVerifier.clear();
            const container = document.querySelector('#sign-in-button');
            if (container) {
                container.outerHTML = `<div id='sign-in-button'></div>`;
            }
        }
        this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
            size: 'invisible',
            callback: (response) => {
                console.log('signInWithPhoneNumber::response', response);
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
            },
        });
        const phone = VN_PHONE_NUMER_PREFIX + phoneNumber.substr(1, 10);
        return from(this.afAuth.auth.signInWithPhoneNumber(phone, this.recaptchaVerifier)).pipe(
            map(data => data.verificationId)
        );
    }

    handleLoginNative(phoneNumber: string) {
        const verificationId$ = new Subject<string>();
        cfaSignInPhoneOnCodeSent().pipe(
            timeout(15000),
        ).subscribe(vId => {
            console.log('cfaSignInPhoneOnCodeSent: ', vId);
            verificationId$.next(vId);
            verificationId$.complete();
        }, error => {
            verificationId$.error(error);
        });

        cfaSignIn('phone', {phone: this.utilsService.convertPhoneToE164Format(phoneNumber)}).subscribe(
            res => {
                console.log('cfaSignIn', res);
                this.autoLoginSubject$.next(res);
            },
            err => verificationId$.error(err),
        );

        return verificationId$.asObservable();
    }

    async verifyCode(phoneNumber: string, code: string) {
        const response = await this.authApiService.verifyOtp(phoneNumber, code).toPromise();
        return this.afAuth.auth.signInWithCustomToken(response.token);
    }

    // verifyCode(verificationId: string, code: string) {
    //     const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    //     return this.afAuth.auth.signInWithCredential(credential);
    // }

    isAuthenticated() {
        return this.afAuth.user.pipe(
            map(user => !!user)
        );
    }

    async logOut() {
        this.notificationService.unRegisterToken();
        try {
            this.mixpanelService.track(TRACKING_EVENTS.LOGOUT);
            setTimeout(() => {
                this.mixpanelService.reset();
            }, 5000);
            await this.removeNotificationTokens();
        } catch (e) {
            console.error(e);
        }
        removeFirestoreListening$.next(true);
        setTimeout(() => {
            if (this.utilsService.isMobile()) {
                return Promise.all([
                    cfaSignOut().toPromise(),
                    this.afAuth.auth.signOut(),
                ]);
            }
            return this.afAuth.auth.signOut();
        }, 2000);
        Sentry.configureScope((scope) => {
            scope.setUser(null);
            scope.clear();
        });
    }

    private async removeNotificationTokens() {
        const device = await Plugins.Device.getInfo();
        const user = await this.afAuth.user.pipe(first()).toPromise();
        if (device.platform === 'web' || !user) {
            return;
        }
        const tokens = await this.fs
            .collection(
                'userNotificationTokens',
                ref => ref.where('uid', '==', user.uid)
                    .where('deviceId', '==', device.uuid)
            ).get().toPromise();
        for (const token of tokens.docs) {
            await token.ref.delete();
        }
        return true;
    }

    signUp(phoneNumber: string, email: string, nationalId: string, name: string, tenantId: string): Observable<any> {
        const url = environment.functionsUrl + '/tizo/signUp';
        return this.http.post<any>(url, {
            tenantId,
            client: {
                phoneNumber,
                email,
                nationalId,
                name
            }
        });
    }

    getCurrentToken$() : Observable<string | null> {
        return this.afAuth.idToken.pipe(
            // takeUntil(removeFirestoreListening$)
        );
    }
}
