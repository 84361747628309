import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from './date-format.pipe';
import { RemoveCountryCodePipe } from './remove-country-code.pipe';
import { PercentageFormatPipe } from './percentage-format.pipe';
import { CallbackFilterPipe } from '@shared/pipes/callback-filter.pipe';
import { LocalSearchPipe } from './local-search.pipe';
import { SortPipe } from './sort.pipe';
import { SafePipe } from './safe.pipe';
import { CcFormatPipe } from './cc-format.pipe';
import { PointsPipe } from './points.pipe';
import { TimestampPipe } from './timestamp.pipe';


@NgModule({
    declarations: [
        DateFormatPipe,
        RemoveCountryCodePipe,
        PercentageFormatPipe,
        CallbackFilterPipe,
        LocalSearchPipe,
        SortPipe,
        SafePipe,
        CcFormatPipe,
        PointsPipe,
        TimestampPipe,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        DateFormatPipe,
        RemoveCountryCodePipe,
        PercentageFormatPipe,
        LocalSearchPipe,
        CallbackFilterPipe,
        SortPipe,
        SafePipe,
        CcFormatPipe,
        PointsPipe,
        TimestampPipe,
    ],
    providers: [
        PointsPipe,
        RemoveCountryCodePipe,
    ]
})
export class PipesModule {
}
