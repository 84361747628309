import { Component, Input } from '@angular/core';
import { User } from '@canalcircle/models';
import { IonicNativeService } from '@core/ionic-native/ionic-native.service';
import { RemoveCountryCodePipe } from '@shared/pipes/remove-country-code.pipe';

@Component({
  selector: 'tizo-referer-code',
  templateUrl: './referer-code.component.html',
  styleUrls: ['./referer-code.component.scss'],
})
export class RefererCodeComponent {
  @Input() user: User;

  constructor(
    private ionicNativeService: IonicNativeService,
    private removeCountryCodePipe: RemoveCountryCodePipe,
  ) { }

  copy() {
    this.ionicNativeService.copy(
      this.removeCountryCodePipe.transform(this.user.authInfo.phoneNumber)
    );
  }
}
