import { NgModule, ModuleWithProviders, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { CURRENCY_CONFIG } from './token';
import { ReactiveFormsModule } from '@angular/forms';
import { ICurrencyInput } from '../models';

const DEFAULT_CURRENCY_INPUT: ICurrencyInput = {
  unit: 1000,
  roundSuffix: '.000',
  currencySuffix: 'đ',
  mask: 'separator',
  thousandSeparator: '.',
  allowNegative: false,
  rounding: true
};

@NgModule({
  declarations: [
    InputCurrencyComponent,
    CurrencyFormatPipe
  ],
  imports: [
    CommonModule,
    NgxMaskModule,
    ReactiveFormsModule
  ],
  exports: [
    InputCurrencyComponent,
    CurrencyFormatPipe
  ],
  providers: [
    CurrencyFormatPipe,
    {
      provide: CURRENCY_CONFIG,
      useValue: DEFAULT_CURRENCY_INPUT
    }
  ]
})
export class InputCurrencyModule {
  static forRoot(config?: Partial<ICurrencyInput>): ModuleWithProviders<InputCurrencyModule> {
    return {
      ngModule: InputCurrencyModule,
      providers: [
        {
          provide: CURRENCY_CONFIG,
          useValue: config ? { ...DEFAULT_CURRENCY_INPUT, ...config } : DEFAULT_CURRENCY_INPUT
        }
      ]
    };
  }
}
