import { NotificationType } from '@canalcircle/models';

export const VSLANotificationTypes = [
  NotificationType.VSLA_NEW_MEETING_INVITATION,
];

export const GeneralNotificationTypes = [
  NotificationType.LOAN_REQUEST_SUBMITTED,
  NotificationType.LOAN_REQUEST_ACCEPT,
  NotificationType.LOAN_REQUEST_REJECT,
  NotificationType.LOAN_REQUEST_CLAIMED,
  NotificationType.LOAN_DISBURSEMENT,
  NotificationType.TIZO_CTA,
  NotificationType.MARKETING,
  NotificationType.BUY_CODE_CARD
];

