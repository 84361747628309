import { Injectable } from '@angular/core';
import { Platform, ToastController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

@Injectable({
  providedIn: 'root'
})
export class IonicNativeService {

  constructor(
    private platForm: Platform,
    private toastCtrl: ToastController,
    private clipBoardCtrl: Clipboard,
    private  photoViewerCtrl: PhotoViewer
  ) {
  }

  copy(text: string) {
    if (this.platForm.is('cordova')) {
      this.clipBoardCtrl.copy(text).then(res => {
        this.showToast('Đã sao chép ');
      });
    } else {
      navigator.clipboard.writeText(text).then(() => {
        this.showToast('Đã sao chép ');
      });
    }
  }

  viewPhoto(url: string) {
    return this.photoViewerCtrl.show(url);
  }

  private async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      position: 'bottom',
      duration: 2000
    });
    await toast.present();
  }
}
