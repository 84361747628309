import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';

const { Network } = Plugins;
interface NetworkStatus {
  connected: boolean;
  connectionType: any;
}
@Injectable()
export class NetworkService {
  private status$: BehaviorSubject<NetworkStatus> = new BehaviorSubject(null);
  constructor() {

  }
  listenStatusChange() {
    Network.addListener('networkStatusChange', (status) => {
      console.log("Network status changed", status);
      this.status$.next(status);
    });
  }
  getStatus() {
    return this.status$;
  }
}
