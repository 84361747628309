import '@patches/fix-sign-out-subscription';
import { Component } from '@angular/core';

import { ModalController, NavController, Platform } from '@ionic/angular';
import { MixpanelService, TRACKING_EVENTS } from '@services/mixpanel.service';
import { ConfigService } from '@services/config.service';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { Router, NavigationEnd } from '@angular/router';
import { TabService } from '@services/tab.service';
import { NotificationService } from 'src/app/notification/services/notification.service';
import { LocationService } from '@services/location.service';
import { UserService } from '@services/user.service';
import { combineLatest, Observable } from 'rxjs';
import { User } from '@shared/models';
import { ROUTES } from '@shared/contants';
import { FeatureService, ITizoFeatureConfig } from '@services/feature.service';
import { AuthService } from '@services/auth.service';
import { IonicCoreService } from '@services/ionic-core.service';
import { default as packageJson } from '../../package.json';
import { UtilsService } from '@services/utils.service';
import { UpdateService } from '@services/update.service';
import { StaticPageComponent } from '@core/modals/static-page/static-page.component';
import { mapTo, map } from 'rxjs/operators';
import { untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { environment } from '@environments/environment';
import { FineractService } from '@services/fineract.service';

const { SplashScreen, StatusBar } = Plugins;
const { FirebaseAnalytics } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  user$: Observable<User>;
  features: Array<ITizoFeatureConfig> = [];
  featuresCondition: any = {};
  isAvailaleReferer$: Observable<boolean>;
  version: string;
  build: string;
  envName: string;
  // menuConditions: any = {};
  constructor(
    private platform: Platform,
    private mixpanelService: MixpanelService,
    private configService: ConfigService,
    private route: Router,
    private tabService: TabService,
    private locationService: LocationService,
    private notificationService: NotificationService,
    private userService: UserService,
    private navCtrl: NavController,
    private featureService: FeatureService,
    private authService: AuthService,
    private ionicCoreService: IonicCoreService,
    private utilsService: UtilsService,
    private updateService: UpdateService,
    private modalCtrl: ModalController,
  ) {
    this.version = packageJson.version;
    this.build = packageJson.build;
    this.envName = environment.name;
    this.initializeApp();
    this.updateService.handleUpdate();
    this.configService.loadAllConfig();
    this.notificationService.init();
    this.locationService.init();
    this.configService.isLoadDoneAllConfig().subscribe(done => {
      SplashScreen.hide({ fadeOutDuration: 1000 });
    });
    this.route.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.onRoute(event);
      }
    });
    this.user$ = this.userService.getCurrentUser();
    this.loadFeaturesConfig();
    this.loadReferer();
    this.assignUserIdToAnalytics();
    this.checkAvailableCrossSelling();
  }

  onRoute(event: NavigationEnd) {
    const url = event.urlAfterRedirects.split('/');
    const pageName = url[url.length - 1];
    const pageNotHideTabs = ['dashboard', 'groups', 'loader', 'welcome-finish', 'update-info'];
    if (url.indexOf('vsla') !== -1 && pageNotHideTabs.indexOf(pageName) === -1) {
      this.tabService.showHideTabBar(true);
    } else {
      this.tabService.showHideTabBar(false);
    }
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      if (this.platform.is('cordova')) {
        try {
          StatusBar.setBackgroundColor({ color: '#000' });
          StatusBar.setOverlaysWebView({ overlay: false });
        } catch (e) {
          console.log('Error when setBackgroundColor, setOverlaysWebView', e)
        }
      }
      this.mixpanelService.track(TRACKING_EVENTS.OPEN_APP);
    });
  }

  goToProfileEditPage() {
    this.navCtrl.navigateForward(ROUTES.ACCOUNT_EDIT);
  }

  loadFeaturesConfig() {
    this.featureService.getVisibleMenuFeatureConfigs().subscribe(features => {
      this.features = features;
    });
  }

  checkAvailableCrossSelling() {
    combineLatest([
      this.user$,
      this.configService.getAvailableTenantIdsForCSR()
    ])
    .pipe(
        map(([currentUser, availableTenantIds]) => {
          // console.log('checkAvailableCrossSelling',currentUser, availableTenantIds );
          if ( _.isEmpty(currentUser?.tenantIds)) {
            return false;
          }
          const tenantIds = currentUser.tenantIds;
          return _.intersection(tenantIds, availableTenantIds).length > 0;
        })
    ).subscribe(isAvailable => {
      this.featuresCondition.activeCrossSelling = isAvailable;
    });
  }

  isFeatureActive(feature): boolean {
    const featureConditions = feature.conditions || {};
    if (!_.isEmpty(featureConditions)) {
      for (const [key, value] of Object.entries(featureConditions)) {
        if (value != this.featuresCondition[key]) {
          return false;
        }
      }
    }
    return true;
  }

  loadReferer() {
    this.isAvailaleReferer$ = this.featureService.isEnableFeature('referer').pipe(
        // mapTo(true)
    );
  }

  assignUserIdToAnalytics() {
    if (this.utilsService.isMobile()) {
      this.user$.subscribe(user => {
        console.log('assignUserIdToAnalytics', user);
        if (user) {
          FirebaseAnalytics.setUserId({
            userId: user.id,
          });
          FirebaseAnalytics.setUserProperty({
            name: 'phoneNumber',
            value: user.authInfo?.phoneNumber || '',
          });
          FirebaseAnalytics.setUserProperty({
            name: 'displayName',
            value: user.authInfo?.displayName || '',
          });
        }
      });
    }
    
  }

  async doLogOut() {
    await this.ionicCoreService.showLoading();
    this.authService.logOut().then(() => {
      this.ionicCoreService.showToast({ message: 'Đăng xuất thành công' });
    }).finally(() => {
      this.ionicCoreService.hideLoading();
      setTimeout(() => {
        this.navCtrl.navigateRoot(ROUTES.LOGIN());
      }, 1000);
    });
  }

  onClickLogOut() {
    this.ionicCoreService.showAlertConfirm('Bạn có muốn đăng xuất ?', (id) => {
      if (id === 'ok') {
        this.doLogOut();
      }
    });
  }
  async showTermModal() {
    const modal = await this.modalCtrl.create({
      component: StaticPageComponent,
      componentProps: {
        pageKey: 'term'
      }
    });
    modal.present();
  }

  async showPolicyModal() {
    const modal = await this.modalCtrl.create({
      component: StaticPageComponent,
      componentProps: {
        pageKey: 'policy'
      }
    });
    modal.present();
  }
}
