import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPhoneComponent } from './components/input-phone/input-phone.component';
import { NgxMaskModule, MaskPipe } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { PhonePipe } from './phone.pipe';

@NgModule({
  declarations: [InputPhoneComponent, PhonePipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    InputPhoneComponent,
    PhonePipe
  ],
  providers: [
    MaskPipe,
    PhonePipe,
  ]
})
export class InputPhoneModule { }
