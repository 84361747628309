import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActionSheetController } from '@ionic/angular';
import { Camera, PictureSourceType } from '@ionic-native/camera/ngx';
import { UtilsService } from '@services/utils.service';
import { ImagePicker, ImagePickerOptions, OutputType, } from '@ionic-native/image-picker/ngx';
import { ActionSheetButton } from '@ionic/core/dist/types/components/action-sheet/action-sheet-interface';

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    constructor(
        private storage: AngularFireStorage,
        private store: AngularFirestore,
        private camera: Camera,
        private actionSheetCtrl: ActionSheetController,
        private utilsService: UtilsService,
        private imagePicker: ImagePicker,
    ) {
    }

    async pickImage(remoteSavePath: string, customButtons = []): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const top = await this.actionSheetCtrl.getTop();
            if (top) {
                await top.dismiss();
            }

            const buttons = [...customButtons];
            const buttonCamera = {
                text: 'Chụp ảnh',
                handler: async () => {
                    this.actionSheetCtrl.dismiss();
                    const imageDataBase64 = await this.camera.getPicture({
                        sourceType: PictureSourceType.CAMERA,
                        allowEdit: false,
                        destinationType: this.camera.DestinationType.DATA_URL,
                        encodingType: this.camera.EncodingType.JPEG,
                    }).catch(e => {
                        // this error mostly happened when user cancel. so just fine to resolve
                        resolve();
                    });
                    if (!imageDataBase64) {
                        return resolve();
                    }
                    const imageDataUrl = 'data:image/jpeg;base64,' + imageDataBase64;
                    return this.uploadFile(imageDataUrl, remoteSavePath)
                        .then(res => resolve(res.url))
                        .catch(reject);
                },
            };
            const buttonPhoto = {
                text: 'Chọn ảnh từ album',
                handler: async () => {
                    this.actionSheetCtrl.dismiss();
                    const imageDataBase64 = await this.camera.getPicture({
                        sourceType: PictureSourceType.PHOTOLIBRARY,
                        allowEdit: false,
                        destinationType: this.camera.DestinationType.DATA_URL,
                        encodingType: this.camera.EncodingType.JPEG,
                    }).catch(e => {
                        // this error mostly happened when user cancel. so just fine to resolve
                        resolve();
                    });
                    if (!imageDataBase64) {
                        return resolve();
                    }
                    const imageDataUrl = 'data:image/jpeg;base64,' + imageDataBase64;
                    return this.uploadFile(imageDataUrl, remoteSavePath)
                        .then(res => resolve(res.url))
                        .catch(reject);
                },
            };
            const buttonNativeFile = {
                text: 'Chọn ảnh từ file',
                handler: async () => {
                    this.actionSheetCtrl.dismiss();
                    const optionsLibrary: ImagePickerOptions = {
                        maximumImagesCount: 1,
                        quality: 90,
                        outputType: OutputType.DATA_URL,
                    };

                    let hasPermission = await this.imagePicker.hasReadPermission();
                    if (!hasPermission) {
                        await this.imagePicker.requestReadPermission();
                        hasPermission = await this.imagePicker.hasReadPermission();
                        if (!hasPermission) {
                            // no permission
                            return reject('missing permission for pick file');
                        }
                    }
                    const imageDatas = await this.imagePicker.getPictures(optionsLibrary);
                    if (!Array.isArray(imageDatas) || imageDatas.length !== 1) {
                        return resolve();
                    }
                    const imageDataUrl = 'data:image/jpeg;base64,' + imageDatas[0];
                    return this.uploadFile(imageDataUrl, remoteSavePath)
                        .then(res => resolve(res.url))
                        .catch(reject);
                },
            };
            const buttonWebFile = {
                text: 'Chọn ảnh từ file',
                handler: async () => {
                    this.actionSheetCtrl.dismiss();
                    const input = document.createElement('input');
                    input.hidden = true;
                    input.type = 'file';
                    document.body.appendChild(input);
                    input.click();
                    input.addEventListener('change', (event) => {
                        console.log(event);
                        const file = (event.target as HTMLInputElement).files[0];
                        if (!file) {
                            return resolve();
                        }
                        console.log(file);
                        if (!['image/png', 'image/jpeg'].includes(file.type)) {
                            return reject('Vui lòng chọn định dạng ảnh jpg');
                        }
                        const reader: FileReader = new FileReader();
                        reader.onload = async (e: any) => {
                            const imageDataUrl: string = e.target.result;
                            return this.uploadFile(imageDataUrl, remoteSavePath)
                                .then(res => {
                                    input.remove();
                                    resolve(res.url);
                                })
                                .catch(err => {
                                    input.remove();
                                    reject(err);
                                });
                        };
                        reader.onerror = async (e) => {
                            input.remove();
                            reject(e);
                        };
                        reader.readAsDataURL(file);
                    });
                },
            };
            const buttonCancel = {
                text: 'Hủy',
                handler: async () => {
                    resolve();
                    this.actionSheetCtrl.dismiss();
                },
            };

            if (this.utilsService.isMobile()) {
                // @TODO: can not handle native file. Because of requesting permission is not awaitable :(
                // buttons.push(buttonCamera, buttonPhoto, buttonNativeFile, buttonCancel);
                buttons.push(buttonCamera, buttonPhoto, buttonCancel);
            } else {
                buttons.push(buttonWebFile, buttonCancel);
            }

            const actionSheet = await this.actionSheetCtrl.create({
                buttons,
                backdropDismiss: false,
            });

            await actionSheet.present();
        });
    }

    async uploadFile(dataUrl, path): Promise<{
        name,
        path,
        url,
    }> {
        const name = this.store.createId();
        return new Promise(async (resolve, reject) => {
            // base64str = await reduceBase64Size(base64str, 100000);
            const storageRef = this.storage.ref(path);
            const result = await storageRef.child(path).child(name).putString(dataUrl, 'data_url');

            if (result && result.ref) {
                const url = await result.ref.getDownloadURL();
                resolve({
                    name,
                    path,
                    url,
                });
                return;
            }
            reject();
        });
    }
}
