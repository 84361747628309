import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'tizo-modal-primary',
  templateUrl: './modal-primary.component.html',
  styleUrls: ['./modal-primary.component.scss'],
})
export class ModalPrimaryComponent implements OnInit {
  @Input() title = '';
  @Input() showCloseButton = true;
  constructor(
    public modalCtrl: ModalController,
  ) {
  }
  ngOnInit() {

  }
  dismiss() {
    this.modalCtrl.dismiss(null);
  }

}

