import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@services/config.service';
import { FineractService } from '@services/fineract.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  token: string;
  constructor(
    private configService: ConfigService,
    private fineractService: FineractService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // is fineract api
    if (req.headers.has('fineract-platform-tenantid')) {
      const fineractToken = this.fineractService.tokensByTenant.get(req.headers.get('fineract-platform-tenantid'));
      if (fineractToken) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Basic ' + fineractToken
          }
        });
      }
    } else {
      // is normal cloud func api
      if (this.configService.userToken) {
        req = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + (this.configService.userToken)
          }
        });
      }
    }

    return next.handle(req);
  }
}
