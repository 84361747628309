import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoFocusDirective } from './auto-focus.directive';
import { IfAuthenticateDirective } from './if-authenticate.directive';
import { IfEnableFeatureDirective } from './if-enable-feature.directive';



@NgModule({
  declarations: [AutoFocusDirective, IfAuthenticateDirective, IfEnableFeatureDirective],
  imports: [
    CommonModule
  ],
  exports: [
    AutoFocusDirective,
    IfAuthenticateDirective,
    IfEnableFeatureDirective
  ]
})
export class DirectivesModule { }
