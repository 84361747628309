import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TabService {

  constructor() { }

  showHideTabBar(hideTabbar: boolean) {
    const tabbarElement = document.getElementById('main-tabBar');
    if (tabbarElement) {
      if (hideTabbar) {
        tabbarElement.style.display = 'none';
      } else {
        tabbarElement.style.display = 'flex';
      }
    }
  }
}
