<ion-button
    mode="ios"
    [expand]="expand"
    [fill]="fill"
    [color]="color"
    [shape]="shape"
    [size]="size"
    [style]="btnStyle"
    [disabled]="disabled"
    (click)="btnClick.emit();">
  <ng-content></ng-content>
</ion-button>
