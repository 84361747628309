import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NetworkComponent } from './component/network/network.component';
import { NetworkService } from './service/network.service';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [
    NetworkComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  exports: [
    NetworkComponent
  ]
})
export class NetworkModule {
  static forRoot(config?: any): ModuleWithProviders {
    return {
      ngModule: NetworkModule,
      providers: [NetworkService]
    }
  }
}
