import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfigService } from '@services/config.service';
import { StaticPage } from '@shared/models/staticPage';

@UntilDestroy()
@Component({
  selector: 'tizo-static-page',
  templateUrl: './static-page.component.html',
  styleUrls: ['./static-page.component.scss'],
})
export class StaticPageComponent implements OnInit {
  @Input() pageKey: string;
  page: StaticPage;
  isLoading;
  constructor(
    private  modalCtrl: ModalController,
    private  configService: ConfigService,
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    console.log(this.pageKey);
    this.configService.getStaticPage$(this.pageKey).pipe(
      untilDestroyed(this),
    ).subscribe(page => {
      this.page = page;
      this.isLoading = false;
    }, e => {
      console.error(e);
      this.isLoading = false;
    });
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }
}
