import { Component, Input, OnInit } from '@angular/core';
import { IPlatformVersionInfo } from '@canalcircle/models';
import { ModalController } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'tizo-update-popup-modal',
  templateUrl: './update-popup-modal.component.html',
  styleUrls: ['./update-popup-modal.component.scss'],
})
export class UpdatePopupModalComponent implements OnInit {
  @Input() platformVersion: IPlatformVersionInfo;
  @Input() mandatory: boolean;
  constructor(
    private modalCtl: ModalController,
    private market: Market,
    private utilsService: UtilsService,
  ) { }

  ngOnInit() { }

  onOk() {
    if (this.utilsService.isAndroid()) {
      this.market.open('com.canalcircle.tizoapp');
    } else if (this.utilsService.isIOS()) {
      this.market.open('1471548197');
    }
  }

  onCancel() {
    this.modalCtl.dismiss();
  }

}
